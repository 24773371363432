import { Heading } from '@chakra-ui/react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { FC } from 'react';

import s from './AppHeaderLink.module.scss';
import clsx from 'clsx';

interface Props {
  link: string;
  title: string;
}

export const AppHeaderLink: FC<Props> = ({ link, title }) => {
  const router = useRouter();

  const isActive = router.pathname.includes(link);

  return (
    <Link href={link}>
      <Heading
        className={clsx(s.title, {
          [s.active]: isActive
        })}
        as="h3"
      >
        {title}
        <div className={s.indicator} />
      </Heading>
    </Link>
  );
};

export default AppHeaderLink;
