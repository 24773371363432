import { AspectRatio, Box, ChakraProps, Circle, Text } from '@chakra-ui/react';
import Image from 'next/image';
import React, { FC, useMemo } from 'react';
import CryptoIcons from 'base64-cryptocurrency-icons';

// @ts-ignore
import weth from '@app/assets/images/tokens/weth.svg?url';
import { ICONS_STORE } from '@app/components/TokenIcon/iconsStore';

interface Props extends ChakraProps {
  symbol?: string;
  iconUrl?: string | null;
  width?: string;
  height?: string;
}

export const TokenIcon: FC<Props> = ({
  symbol,
  iconUrl,
  width = '24px',
  height = '24px',
  ...rest
}) => {
  const url = useMemo(() => {
    if (iconUrl) {
      return iconUrl;
    }

    if (
      symbol?.toLowerCase() === 'eth' ||
      symbol?.toLowerCase() === 'weth' ||
      symbol?.toLowerCase() === 'wtcore' ||
      symbol?.toLowerCase() === 'wcore'
    ) {
      return ICONS_STORE.CORE.src;
    }

    if (symbol) {
      if (ICONS_STORE[symbol]) {
        return ICONS_STORE[symbol].src;
      }

      const icon = CryptoIcons[symbol];

      return icon ? icon.icon : null;
    }

    return null;
  }, [iconUrl, symbol]);

  return (
    <AspectRatio
      ratio={1}
      w={width}
      h={height}
      pos="relative"
      borderRadius="100%"
      overflow="hidden"
      {...rest}
    >
      {url ? (
        <Image src={url} alt="Coin" fill />
      ) : (
        <Circle size="100%" bg="neutral.400" color="black">
          <Text fontSize="10px" fontWeight={600}>
            {symbol?.slice(0, 3)}
          </Text>
        </Circle>
      )}
    </AspectRatio>
  );
};

export default TokenIcon;
