import React, { useEffect, useState } from 'react';

import s from './ChainBlockIndicator.module.scss';
import clsx from 'clsx';
import { watchBlockNumber } from '@thirdweb-dev/sdk';
import { getActiveChain } from '@app/constants/chains';
import { Spinner } from '@chakra-ui/react';

export const ChainBlockIndicator = () => {
  const [block, setBlock] = useState<number | null>(null);

  const unsubscribe = watchBlockNumber({
    network: getActiveChain().chainId,
    onBlockNumber: (blockNumber: number) => {
      setBlock(blockNumber);
    }
  });

  useEffect(() => {
    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <div
      className={clsx(s.root, {
        [s.connected]: true
      })}
    >
      <div className={s.indicator} />
      <div className={s.label}>
        {block !== null ? block : <Spinner width="9px" height="9px" />}
      </div>
    </div>
  );
};
