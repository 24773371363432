import { Flex, Box, Text } from '@chakra-ui/react';
import Image from 'next/image';
import React from 'react';

import noDataImg from '@app/assets/images/placeholder/noData.png';

export const NoDataView = () => {
  return (
    <Flex
      height="100%"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <Box>
        <Image src={noDataImg.src} width={900} height={200} alt="NoData" />
      </Box>
      <Box>
        <Text
          sx={{
            fontSize: '24px',
            fontWeight: 500,
            color: 'white',
            mb: '16px',
            textAlign: 'center'
          }}
        >
          There are no transactions
        </Text>
        <Text
          sx={{
            fontSize: '18px',
            fontWeight: 400,
            color: 'gray.4',
            textAlign: 'center'
          }}
        >
          Looks like you don’t have any tokens, start by depositing tokens now.
        </Text>
      </Box>
    </Flex>
  );
};
