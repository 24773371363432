import { Box, VStack, Text } from '@chakra-ui/react';
import React from 'react';

import Image from 'next/image';
import appLogo from '@app/assets/images/logo/appLogo.png';

import s from './ConnectedWalletWelcomeScreen.module.scss';

export const ConnectWalletWelcomeScreen = () => {
  return (
    <Box p="24px" bg="gray.8" h="100%">
      <VStack alignItems="flex-start" justifyContent="space-between" h="100%">
        <div className={s.logo}>
          <Image src={appLogo} height={34} alt="App logo" />
        </div>
        <VStack alignItems="flex-start">
          <Text
            fontSize="20px"
            fontWeight="500"
            lineHeight="26px"
            color="white"
            mb="8px"
          >
            Connect Wallet
          </Text>
          <Text
            fontSize="16px"
            fontWeight="400"
            lineHeight="24px"
            color="whiteOpacity.50"
          >
            Select your wallet from the options to get started.
          </Text>
        </VStack>
      </VStack>
    </Box>
  );
};
