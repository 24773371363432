import { HStack, Text } from '@chakra-ui/react';
import Link from 'next/link';
import Image from 'next/image';
import React, { FC, PropsWithChildren } from 'react';
import { useMedia } from 'react-use';

import appLogo from '@app/assets/images/logo/appLogo.png';
import { Account } from '@app/components/AppHeader/components/Account';
import { MobileMenu } from '@app/components/AppHeader/components/MobileMenu';

import s from './AppHeader.module.scss';

export const AppHeader: FC<PropsWithChildren> = ({ children }) => {
  const isMobile = useMedia('(max-width: 960px)', false);

  return (
    <div className={s.root}>
      <div className={s.wrapper}>
        <Link href="/" className={s.logo}>
          <Image src={appLogo} height={37} alt="App logo" />
        </Link>
        <div className={s.desktop}>{children}</div>
        <Account />
      </div>
    </div>
  );
};
