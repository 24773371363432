export const META_TAGS_DEFAULT_CONFIG = {
  twitterCardType: 'summary_large_image',
  twitterCreator: '@name',
  twitterImageAlt: '',
  twitterCite: '@name',
  title: 'CODEX',
  description: '',
  url: 'https://codex-dapp.vercel.app',
  image: 'https://codex-dapp.vercel.app/assets/images/banner.png'
};
