import { Box } from '@chakra-ui/react';
import Link from 'next/link';
import React from 'react';

import s from './AppFooter.module.scss';
import { ChainBlockIndicator } from '@app/components/ChainBlockIndicator';
import { Icon } from '@app/components/Icon';
import { config } from '@app/config';

export const AppFooter: React.FC = () => {
  return (
    <Box className={s.root}>
      <Box className={s.wrapper}>
        <ChainBlockIndicator />
        <div className={s.content}>
          <div className={s.links}>
            <Link href={config.documentation} target="_blank" rel="noreferrer">
              <span className={s.link}>
                Documentation <Icon name="externalLink" />
              </span>
            </Link>
          </div>
        </div>
      </Box>
    </Box>
  );
};
