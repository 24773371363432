import { HStack } from '@chakra-ui/react';
import CryptoIcons from 'base64-cryptocurrency-icons';
import React, { FC } from 'react';

import { TokenIcon } from '@app/components/TokenIcon';

interface Props {
  token0Symbol: string;
  token1Symbol: string;
  token0Icon?: string;
  token1Icon?: string;
}

export const TokensPair: FC<Props> = ({
  token0Symbol,
  token1Symbol,
  token0Icon,
  token1Icon
}) => {
  const tokenFromIcon = CryptoIcons[token0Symbol];
  const tokenToIcon = CryptoIcons[token1Symbol];

  return (
    <HStack gap={0}>
      <TokenIcon
        symbol={token0Symbol}
        iconUrl={token0Icon ?? tokenFromIcon?.icon}
        width="32px"
        height="32px"
      />
      <TokenIcon
        symbol={token1Symbol}
        iconUrl={token1Icon ?? tokenToIcon?.icon}
        sx={{ transform: 'translateX(-8px)' }}
        width="32px"
        height="32px"
      />
    </HStack>
  );
};
