import { Box } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import React from 'react';

import { AppFooter } from '@app/components/AppFooter';
import { AppHeader } from '@app/components/AppHeader';
import { AppHeaderLink } from '@app/components/AppHeaderLink';
import Metadata, { MetadataProps } from '@app/components/Metadata';
import { APP_ROUTES } from '@app/constants/routes';

import s from './MainLayout.module.scss';
import { MobileFooter } from '@app/components/MobileFooter';

interface MainLayoutProps {
  children: React.ReactNode;
  metadata?: MetadataProps;
}

const MainLayout: React.FC<MainLayoutProps> = ({ children, metadata }) => {
  return (
    <Box className={s.root}>
      <Metadata {...metadata} />
      <AppHeader>
        <AppHeaderLink link={APP_ROUTES.swapPage} title="Swap" />
        <AppHeaderLink link={APP_ROUTES.poolsPage} title="Pools" />
        <AppHeaderLink link={APP_ROUTES.positions} title="Liquidity" />
      </AppHeader>
      <div className={s.main}>{children}</div>
      <MobileFooter>
        <AppHeaderLink link={APP_ROUTES.swapPage} title="Swap" />
        <AppHeaderLink link={APP_ROUTES.poolsPage} title="Pools" />
        <AppHeaderLink link={APP_ROUTES.positions} title="Liquidity" />
      </MobileFooter>
      <AppFooter />
    </Box>
  );
};

export default MainLayout;
