import { Button, ButtonProps, Text } from '@chakra-ui/react';
import React, { FC } from 'react';

interface Props extends ButtonProps {
  disabled?: boolean;
  isLoading: boolean;
  onClick: () => void;
  label: string;
  loadingText?: string;
  maxWidth?: string | number;
}

export const SubmitButton: FC<Props> = ({
  isLoading,
  onClick,
  label,
  loadingText,
  disabled,
  maxWidth,
  ...rest
}) => {
  return (
    <Button
      variant="primary"
      w="100%"
      position="relative"
      isDisabled={disabled}
      isLoading={isLoading}
      loadingText={loadingText}
      // mt="16px"
      // py="10px"
      px="20px"
      onClick={onClick}
      sx={{
        maxWidth
      }}
      {...rest}
    >
      {label}
    </Button>
  );
};
