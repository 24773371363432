export enum QueryKeys {
  TOKENS_LIST = 'TOKENS_LIST',
  TOKEN_DETAILS = 'TOKEN_DETAILS',
  POPULAR_TOKENS = 'POPULAR_TOKENS',
  PAIRS = 'PAIRS',
  PAIR_DETAILS = 'PAIR_DETAILS',
  TOKENS_IDS = 'TOKENS_IDS',
  TOKENS_PRICES = 'TOKENS_PRICES',
  FACTORY_DATA = 'FACTORY_DATA',
  PAIR_DAY_DATA = 'PAIR_DAY_DATA',
  TOKEN_PRICE = 'TOKEN_PRICE',
  PAIR_HISTORICAL_DATA = 'PAIR_HISTORICAL_DATA',
  PAIR_HISTORICAL_DATA_LAST_DAY = 'PAIR_HISTORICAL_DATA_LAST_DAY',
  TOKEN_BALANCE = 'TOKEN_BALANCE',
  USER_POSITIONS = 'USER_POSITIONS',
  SP_NFTS_POSITIONS = 'SP_NFTS_POSITIONS',
  PAIR_USER_POSITIONS = 'PAIR_USER_POSITIONS',
  STAKING_POSITION = 'STAKING_POSITION',
  POOL_TOKEN_IDS = 'POOL_TOKEN_IDS',
  ALL_PAIRS = 'ALL_PAIRS',
  TRANSACTIONS = 'TRANSACTIONS'
}
