import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { QueryKeys } from 'src/constants/queryKeys';

import { config } from '@app/config';
import { gql, request } from 'graphql-request';

type QueryParams = {
  id: string | undefined;
};

type QueryResponse = { bundles: { ethPrice: string }[] };

const fetcher = (baseUrl: string) => {
  return request<QueryResponse>(
    `${baseUrl}`,
    gql`
      query {
        bundles {
          id
          ethPrice
        }
      }
    `
  );
};

export const useTokenPriceV3 = (
  params: QueryParams,
  options: UseQueryOptions<string | null> = {}
) => {
  return useQuery<string | null>(
    [QueryKeys.TOKEN_PRICE, { params }],
    async () => {
      const response = await fetcher(config.subgraphUrl as string);

      return response?.bundles && response.bundles.length
        ? response.bundles[0].ethPrice
        : '0';
    },
    {
      enabled: !!params.id,
      refetchOnWindowFocus: false,
      staleTime: 5000,
      ...options
    }
  );
};
