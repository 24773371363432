import {
  Flex,
  HStack,
  Text,
  IconButton,
  Button,
  Box,
  Skeleton
} from '@chakra-ui/react';
import { useAddress } from '@thirdweb-dev/react';
import React, { FC, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import { Icon } from '@app/components/Icon';
import { NoDataView } from '@app/components/TransactionsHistory/components/NoDataView';
import { TransactionItem } from '@app/components/TransactionsHistory/components/TransactionItem';
import { useInfiniteTransactionsHistory } from '@app/hooks/history/useTransactionHistory';
import { ListLoader } from '@app/screens/pools/components/ListLoader';
import { config } from '@app/config';

type ViewFilter = 'all' | 'swap' | 'stake' | 'liquidity';

interface Props {
  onClose: (nxtVal?: boolean) => void;
}

export const TransactionsHistory: FC<Props> = ({ onClose }) => {
  const address = useAddress();
  const [filter, setFilter] = useState<ViewFilter>('all');

  const {
    dataFlat,
    hasNextPage,
    fetchNextPage,
    totalLoaded,
    status,
    isFetching
  } = useInfiniteTransactionsHistory({
    view: filter,
    address
  });

  function renderFilterButton(name: ViewFilter, disabled: boolean = false) {
    const isActive = filter === name;

    if (disabled) {
      return null;
    }

    return (
      <Button
        isDisabled={disabled}
        variant="tertiary"
        sx={{
          color: isActive ? 'orange' : 'gray.4',
          bg: isActive ? 'gray.2' : 'transparent',
          textTransform: 'capitalize'
        }}
        onClick={() => setFilter(name)}
      >
        {name}
      </Button>
    );
  }

  function renderContent() {
    if (status === 'loading') {
      return <ListLoader />;
    }

    if (dataFlat?.length) {
      return (
        <InfiniteScroll
          dataLength={totalLoaded}
          next={fetchNextPage}
          hasMore={hasNextPage ?? false}
          loader={<div />}
          scrollableTarget="scrollableDiv"
        >
          {dataFlat?.map(item => {
            return <TransactionItem key={item.id} data={item} />;
          })}
        </InfiniteScroll>
      );
    }

    return <NoDataView />;
  }

  return (
    <Flex
      sx={{
        background: 'gray.9',
        width: '100%',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      <HStack
        justifyContent="space-between"
        alignItems="center"
        mb="48px"
        p="24px 24px 0"
      >
        <Text sx={{ fontSize: '28px', fontWeight: 500, color: 'white' }}>
          Transaction History
        </Text>
        <IconButton
          aria-label="close"
          onClick={() => onClose()}
          sx={{
            borderRadius: '4px',
            border: '1px solid',
            borderColor: 'whiteOpacity.6',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '11px 10px'
          }}
        >
          <Icon name="close" />
        </IconButton>
      </HStack>
      <HStack
        justifyContent="space-between"
        alignItems="center"
        p="0 24px 24px"
      >
        {renderFilterButton('all')}
        {renderFilterButton('swap')}
        {renderFilterButton('liquidity')}
      </HStack>
      <Box
        sx={{ padding: '24px' }}
        id="scrollableDiv"
        overflowY="auto"
        height="calc(100dvh - 200px)"
      >
        {renderContent()}
      </Box>
    </Flex>
  );
};

export default TransactionsHistory;
