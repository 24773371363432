import { Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/menu';
import { Box, Button, HStack, VStack, Text } from '@chakra-ui/react';
import {
  useAddress,
  useChain,
  useDisconnect,
  useNetworkMismatch,
  useSwitchChain,
  useWalletConfig
} from '@thirdweb-dev/react';
import { AnimatePresence, motion } from 'framer-motion';
import Link from 'next/link';
import React from 'react';
import { useMedia, useToggle } from 'react-use';

import { CopyButton } from '@app/components/CopyButton';
import { Icon } from '@app/components/Icon';
import { TransactionsHistory } from '@app/components/TransactionsHistory';
import { formatWalletAddress } from '@app/helpers/format';
import { getActiveChain } from '@app/constants/chains';

import s from './ConnectedWallet.module.scss';
import clsx from 'clsx';
import appLogo from '@app/assets/images/logo/appLogoBw.png';
import Image from 'next/image';

const activeChain = getActiveChain();

export const ConnectedWallet = () => {
  const [showTxHistory, toggleShowTxHistory] = useToggle(false);
  const disconnect = useDisconnect();
  const address = useAddress();
  const chain = useChain();
  const isMobile = useMedia('(max-width: 960px)', false);
  const isMismatchedNetwork = useNetworkMismatch();
  const switchChain = useSwitchChain();

  const walletConfig = useWalletConfig();

  const walletName = walletConfig?.meta.name;
  const walletLogo = walletConfig?.meta.iconURL;

  if (!address) {
    return null;
  }

  return (
    <>
      <Menu placement="bottom-end">
        <MenuButton
          // as={Button}
          sx={{
            padding: '8px 24px',
            background: 'transparent',
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '21px',
            width: '100%',
            borderRadius: '6px'
          }}
          _hover={{
            background: 'gray.5'
          }}
        >
          <div className={s.control}>
            {walletLogo && (
              <Image
                src={walletLogo}
                height={24}
                width={24}
                alt="Wallet logo"
              />
            )}
            <span className={s.desktop}>{formatWalletAddress(address)}</span>
            {isMismatchedNetwork && (
              <div className={s.warningWrapper}>
                <Icon name="toastWarning" />
              </div>
            )}
          </div>
        </MenuButton>
        <MenuList className={s.menu}>
          <MenuItem minH="48px" p="12px" gap="12px">
            <HStack width="100%" gap="8px">
              {walletLogo && (
                <Image
                  src={walletLogo}
                  height={24}
                  width={24}
                  alt="Wallet logo"
                />
              )}
              <VStack gap={0} alignItems="flex-start">
                <div className={s.menuItem}>{formatWalletAddress(address)}</div>
              </VStack>
              <HStack sx={{ marginLeft: 'auto' }}>
                <CopyButton color="gray.2" text={address} />
              </HStack>
            </HStack>
          </MenuItem>
          {isMismatchedNetwork && (
            <MenuItem
              minH="48px"
              p="12px"
              gap="8px"
              borderBottom="1px solid"
              borderColor="whiteOpacity.12"
              onClick={async () => {
                await switchChain(activeChain.chainId);
              }}
            >
              <HStack width="100%" alignItems="center" gap="8px">
                <Icon name="toastWarning" />
                <div className={clsx(s.menuItem, s.warning)}>
                  Change Network in wallet
                </div>
              </HStack>
            </MenuItem>
          )}
          {/*<MenuItem*/}
          {/*  minH="48px"*/}
          {/*  p="12px"*/}
          {/*  gap="8px"*/}
          {/*  borderBottom="1px solid"*/}
          {/*  borderColor="whiteOpacity.12"*/}
          {/*  onClick={() => toggleShowTxHistory()}*/}
          {/*>*/}
          {/*  <HStack width="100%" alignItems="center">*/}
          {/*    <Icon*/}
          {/*      name="trxHistory"*/}
          {/*      style={{ width: '20px', height: '20px' }}*/}
          {/*    />*/}
          {/*    <Text color="whiteOpacity.50" fontSize={14}>*/}
          {/*      Transaction history*/}
          {/*    </Text>*/}
          {/*  </HStack>*/}
          {/*</MenuItem>*/}
          <MenuItem minH="48px" p="12px" gap="8px" onClick={() => disconnect()}>
            <HStack width="100%" alignItems="center">
              <Text color="#FF0000" fontSize={14}>
                Disconnect
              </Text>
            </HStack>
          </MenuItem>
        </MenuList>
      </Menu>
      <AnimatePresence>
        {showTxHistory && (
          <motion.div
            style={{
              width: isMobile ? '100vw' : '512px',
              position: 'fixed',
              right: 0,
              top: 0,
              bottom: 0,
              zIndex: 110
            }}
            transition={{
              duration: 0.5,
              ease: [0.2, 0.71, 0.2, 1.01]
            }}
            initial={{
              transform: 'translateX(100%)'
            }}
            animate={{
              transform: 'translateX(0)'
            }}
            exit={{
              transform: 'translateX(100%)'
            }}
          >
            <TransactionsHistory onClose={toggleShowTxHistory} />
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};
