import React, { PropsWithChildren, FC, useRef } from 'react';

import s from './MobileFooter.module.scss';
import { IconButton } from '@chakra-ui/react';
import { Icon } from '@app/components/Icon';
import { useToggle } from 'react-use';
import { AnimatePresence, motion } from 'framer-motion';
import { useOnClickOutside } from '@app/hooks/other/useOnClickOutside';
import { ChainBlockIndicator } from '@app/components/ChainBlockIndicator';
import { config } from '@app/config';

export const MobileFooter: FC<PropsWithChildren> = ({ children }) => {
  const [isOpen, toggleIsOpen] = useToggle(false);
  const menuRef = useRef<HTMLDivElement>(null);
  const toggleRef = useRef<HTMLDivElement>(null);

  useOnClickOutside([menuRef, toggleRef], () => toggleIsOpen(false));

  return (
    <>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            className={s.overlay}
            transition={{ duration: 0.4 }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          />
        )}
      </AnimatePresence>
      <div className={s.container}>
        <AnimatePresence>
          {isOpen && (
            <motion.div
              className={s.panel}
              ref={menuRef}
              transition={{ duration: 0.4 }}
              initial={{ opacity: 0, transform: 'translateY(100%)' }}
              animate={{ opacity: 1, transform: 'translateY(-100%)' }}
              exit={{ opacity: 0, transform: 'translateY(100%)' }}
            >
              <div className={s.menuItem}>
                <span className={s.link}>Documentation</span>
                <a href={config.documentation} target="_blank" rel="noreferrer">
                  <Icon name="externalLink" />
                </a>
              </div>
              <div className={s.menuItem}>
                <ChainBlockIndicator />
              </div>
              <div className={s.divider} />
              <div className={s.menuItem}>
                <span className={s.link}>Privacy policy</span>
              </div>
              <div className={s.menuItem}>
                <span className={s.link}>Terms and Conditions</span>
              </div>
              <div className={s.menuItem}>
                <span>© 2024 CODEX</span>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
        <div className={s.root}>
          {children}
          <div ref={toggleRef}>
            <IconButton aria-label="more" onClick={toggleIsOpen}>
              <Icon name="more" />
            </IconButton>
          </div>
        </div>
      </div>
    </>
  );
};
